@use 'sass:map';


$BASE_UNIT: 'rem';
$BASE_VALUE: 1;
$MAP: (
    'EIGHTH':                 #{$BASE_VALUE*0.125}#{$BASE_UNIT},
    'QUARTER':                #{$BASE_VALUE*0.25}#{$BASE_UNIT},
    'HALF':                   #{$BASE_VALUE*0.50}#{$BASE_UNIT},
    'THREE_QUARTERS':         #{$BASE_VALUE*0.75}#{$BASE_UNIT},
    'SEVEN_EIGHTHS':          #{$BASE_VALUE*0.875}#{$BASE_UNIT},

    'ONE':                    #{$BASE_VALUE}#{$BASE_UNIT},

    'ONE_AND_AN_EIGHTH':      #{$BASE_VALUE*1.125}#{$BASE_UNIT},
    'ONE_AND_A_QUARTER':      #{$BASE_VALUE*1.25}#{$BASE_UNIT},
    'ONE_AND_A_HALF':         #{$BASE_VALUE*1.50}#{$BASE_UNIT},
    'ONE_AND_THREE_QUARTERS': #{$BASE_VALUE*1.75}#{$BASE_UNIT},
    'TWO':                    #{$BASE_VALUE*2.00}#{$BASE_UNIT},
    'TWO_AND_A_HALF':         #{$BASE_VALUE*2.50}#{$BASE_UNIT},
    'THREE':                  #{$BASE_VALUE*3.00}#{$BASE_UNIT},
    'FOUR':                   #{$BASE_VALUE*4.00}#{$BASE_UNIT},
    'FIVE':                   #{$BASE_VALUE*5.00}#{$BASE_UNIT},
    'SIX':                    #{$BASE_VALUE*6.00}#{$BASE_UNIT},
    'SEVEN':                  #{$BASE_VALUE*7.00}#{$BASE_UNIT},
    'EIGHT':                  #{$BASE_VALUE*8.00}#{$BASE_UNIT},
    'NINE':                   #{$BASE_VALUE*9.00}#{$BASE_UNIT},
    'TEN':                    #{$BASE_VALUE*10.00}#{$BASE_UNIT},
);

$MAP_AS_NUMBERS: (
    '0_125': #{map.get($MAP, 'EIGHTH')},
    '0_25':  #{map.get($MAP, 'QUARTER')},
    '0_5':   #{map.get($MAP, 'HALF')},
    '0_75':  #{map.get($MAP, 'THREE_QUARTERS')},
    '0_875': #{map.get($MAP, 'SEVEN_EIGHTHS')},

    '1':     #{map.get($MAP, 'ONE')},

    '1_125': #{map.get($MAP, 'ONE_AND_AN_EIGHTH')},
    '1_25':  #{map.get($MAP, 'ONE_AND_A_QUARTER')},
    '1_5':  #{map.get($MAP, 'ONE_AND_A_HALF')},
    '1_75':  #{map.get($MAP, 'ONE_AND_THREE_QUARTERS')},
    '2':     #{map.get($MAP, 'TWO')},
    '2_5':   #{map.get($MAP, 'TWO_AND_A_HALF')},
    '3':     #{map.get($MAP, 'THREE')},
    '4':     #{map.get($MAP, 'FOUR')},
    '5':     #{map.get($MAP, 'FIVE')},
    '6':     #{map.get($MAP, 'SIX')},
    '7':     #{map.get($MAP, 'SEVEN')},
    '8':     #{map.get($MAP, 'EIGHT')},
    '9':     #{map.get($MAP, 'NINE')},
    '10':    #{map.get($MAP, 'TEN')},
)
