.rh-opacity-0 { opacity: 0; }
.rh-opacity-0_1 { opacity: 0.1; }
.rh-opacity-0_2 { opacity: 0.2; }
.rh-opacity-0_3 { opacity: 0.3; }
.rh-opacity-0_4 { opacity: 0.4; }
.rh-opacity-0_5 { opacity: 0.5; }
.rh-opacity-0_6 { opacity: 0.6; }
.rh-opacity-0_7 { opacity: 0.7; }
.rh-opacity-0_8 { opacity: 0.8; }
.rh-opacity-0_9 { opacity: 0.9; }
.rh-opacity-1 { opacity: 1; }
