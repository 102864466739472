@use '../definitions/colours';
@use '../definitions/spacing';
@use '../definitions/typography';

@mixin list-fonts {
    font-size: map-get(typography.$MAP, 'TEXT-L');
    line-height: 2;
}

@mixin rh-ul {
    list-style-type: disc;
    margin-left: map-get(spacing.$MAP, 'HALF');
    padding-left: map-get(spacing.$MAP, 'ONE');

    li {
        @include list-fonts;
        position: relative;

        &::marker {
            color: map-get(colours.$MAP, 'BLUEBERRY_DARK');
            line-height: 1;
            font-size: 1.5rem;
        }
    }
}

@mixin rh-ol {
    list-style-type: none;
    counter-reset: item;
    padding-left: 0;

    li {
        @include list-fonts;

        &::before {
            content: counter(item) '.';
            counter-increment: item;
            font-family: inherit;
            font-size: inherit;
            font-weight: 500;
            color: inherit;
            padding-right: map-get(spacing.$MAP, 'HALF');
        }
    }
}
