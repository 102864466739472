@use '../definitions/typography';
@use '../definitions/font-sizes';

// Fonts
.rh-font-family-gordita { font-family: 'Gordita', sans-serif; }
.rh-font-family-inherit { font-family: inherit; }

// Typography
.rh-text-2xs { font-size: map-get(typography.$MAP, 'TEXT-2XS'); line-height: 1.5; }
.rh-text-xs { font-size: map-get(typography.$MAP, 'TEXT-XS'); line-height: 1.5; }
.rh-text-s { font-size: map-get(typography.$MAP, 'TEXT-S'); line-height: 1.5; }
.rh-text-m { font-size: map-get(typography.$MAP, 'TEXT-M'); line-height: 1.5; }
.rh-text-l { font-size: map-get(typography.$MAP, 'TEXT-L'); line-height: 1.5; }
.rh-text-xl { font-size: map-get(typography.$MAP, 'TEXT-XL'); line-height: 1.5; }

.rh-title-3xs { font-size: map-get(typography.$MAP, 'TITLE-3XS'); font-weight: 500; line-height: 1.5; margin-bottom: 0.875em; }
.rh-title-2xs { font-size: map-get(typography.$MAP, 'TITLE-2XS'); font-weight: 500; line-height: 1.5; margin-bottom: 1em; }
.rh-title-xs { font-size: map-get(typography.$MAP, 'TITLE-XS'); font-weight: 500; line-height: 1.5; margin-bottom: 1.25em; }
.rh-title-s { font-size: map-get(typography.$MAP, 'TITLE-S'); font-weight: 500; line-height: 1.5; margin-bottom: 1.375em; }
.rh-title-m { font-size: map-get(typography.$MAP, 'TITLE-M'); font-weight: 500; line-height: 1.5; margin-bottom: 1.5em; }
.rh-title-l { font-size: map-get(typography.$MAP, 'TITLE-L'); font-weight: 500; line-height: 1.5; margin-bottom: 1.875em; }
.rh-title-2xl { font-size: map-get(typography.$MAP, 'TITLE-2XL'); font-weight: 500; line-height: 1.2; margin-bottom: 1.875em; }
.rh-title-3xl { font-size: map-get(typography.$MAP, 'TITLE-3XL'); font-weight: 700; line-height: 1.2; margin-bottom: 2.5em; }
.rh-title-4xl { font-size: map-get(typography.$MAP, 'TITLE-4XL'); font-weight: 700; line-height: 1.2; margin-bottom: 3em; }

.rh-content {
    .rh-text-s,
    &.rh-text-s { line-height: 1.75; margin-bottom: 1.5em; }

    .rh-text-m,
    &.rh-text-m { line-height: 2; margin-bottom: 1.875em; }

    .rh-text-l,
    &.rh-text-l { line-height: 2; margin-bottom: 2em; }
}

// Font weight
.weight-regular { font-weight: 400; }
.weight-medium { font-weight: 500; }
.weight-bold { font-weight: 700; }

// Line-height (leading)
.leading-xs { line-height: 1; }
.leading-s { line-height: 1.2; }
.leading-m { line-height: 1.5; }
.leading-l { line-height: 1.75; }
.leading-xl { line-height: 2; }

// Decoration
.rh-no-wrap { white-space: nowrap; }
.rh-text-uppercase { text-transform: uppercase; }
.rh-text-lowercase { text-transform: lowercase; }
.rh-text-decoration-line-through { text-decoration: line-through; }
.rh-text-decoration-none { text-decoration: none; }

// Alignment
.rh-text-align-left { text-align: left; }
.rh-text-align-center { text-align: center; }
.rh-text-align-right { text-align: right; }

// Outline
.rh-outline-none { outline: none; }

// Font size
@each $tShirtSize, $value in font-sizes.$MAP {
    .rh-font-size-#{to-lower-case($tShirtSize)} { font-size: #{$value} }
}
