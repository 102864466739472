@use '../definitions/colours';
@use '../functions/stringReplace' as *;


$RH_PREFIX: 'rh-';
$BORDER_COLOUR_PREFIX: $RH_PREFIX + 'border-color-';
$BORDER_PREFIX: $RH_PREFIX + 'border-';
$BORDER_TOP_COLOUR_PREFIX: $BORDER_PREFIX + 'top-color-';          // e.g. rh-border-top-color- with {colour_name} appended
$BORDER_BOTTOM_COLOUR_PREFIX: $BORDER_PREFIX + 'bottom-color-';

// trying not to explode our CSS rules too much, only create rules for this subset of speciality border colours
$BORDER_COLOURS_PREFIXES : 'ston|yuzu|blac';


// Border-radius as percentages
.rh-border-radius-50p { border-radius: 50% }
.rh-border-radius-100p { border-radius: 100% }

// Border-radius as pixels
.rh-border-radius-0 { border-radius: 0 }
.rh-border-radius-1px { border-radius: 1px }
.rh-border-radius-2px { border-radius: 2px }
.rh-border-radius-3px { border-radius: 3px }
.rh-border-radius-4px { border-radius: 4px }
.rh-border-radius-5px { border-radius: 5px }
.rh-border-radius-6px { border-radius: 6px }
.rh-border-radius-7px { border-radius: 7px }
.rh-border-radius-8px { border-radius: 8px }
.rh-border-radius-9px { border-radius: 9px }
.rh-border-radius-10px { border-radius: 10px }


.rh-border-width-top-1px {
    border-width: 1px 0 0 0;
}

.rh-border-width-bottom-1px {
    border-width: 1px 0 0 0;
}

// Border color
@each $name, $colour in colours.$MAP {
    $COLOUR_NAME: to-lower-case(string-replace($name, '_', '-'));   //

    .#{$BORDER_COLOUR_PREFIX}#{$COLOUR_NAME} {
        border-color: #{$colour};
    }

    // Interaction states
    .hover--#{$BORDER_COLOUR_PREFIX}#{$COLOUR_NAME}:hover {
        border-color: #{$colour};
    }

    .active--#{$BORDER_COLOUR_PREFIX}#{$COLOUR_NAME}:active {
        border-color: #{$colour};
    }

    .focus--#{$BORDER_COLOUR_PREFIX}#{$COLOUR_NAME}:focus {
        border-color: #{$colour};
    }


    // repeat for border-top-colour and border-bottom-colour for a subset of frequently used colours
    $COLOUR_SLICE : str-slice($COLOUR_NAME, 1, 4);  // first four characters

    @if str-index($BORDER_COLOURS_PREFIXES, $COLOUR_SLICE) {
        // border-top
        .#{$BORDER_TOP_COLOUR_PREFIX}#{$COLOUR_NAME} {
            border-top-color: #{$colour};
        }

        // border-bottom
        .#{$BORDER_BOTTOM_COLOUR_PREFIX}#{$COLOUR_NAME} {
            border-bottom-color: #{$colour};
        }
    }
}


// Border width as pixels
.rh-border-width-0 { border-width: 0 }
.rh-border-width-1px { border-width: 1px }
.rh-border-width-2px { border-width: 2px }

// Border style
.rh-border-style-solid { border-style: solid }
