$FONT_NAME: 'Gordita';
$FONT_FAMILY_STACK: $FONT_NAME, sans-serif;

@font-face {
    font-family: $FONT_NAME;
    font-weight: 400;
    font-display: swap;
    // Ideally we can alias this path and handle the resolution via our webpack configs
    src: url('../../../fonts/Gordita-regular.woff2') format('woff2'),
        url('../../../fonts/Gordita-regular.woff') format('woff');
}
@font-face {
    font-family: $FONT_NAME;
    font-weight: 500;
    font-display: swap;
    src: url('../../../fonts/Gordita-medium.woff2') format('woff2'),
        url('../../../fonts/Gordita-medium.woff') format('woff');
}
@font-face {
    font-family: $FONT_NAME;
    font-weight: 700;
    font-display: swap;
    src: url('../../../fonts/Gordita-bold.woff2') format('woff2'),
        url('../../../fonts/Gordita-bold.woff') format('woff');
}

