/* only a few of the most frequently-used CSS properties will be represented here */

.rh-align-items-center      { align-items: center; }
.rh-align-items-flex-start  { align-items: flex-start; }
.rh-align-items-flex-end    { align-items: flex-end; }
.rh-align-items-stretch     { align-items: stretch; }

.rh-justify-content-center          { justify-content: center; }
.rh-justify-content-space-around    { justify-content: space-around; }
.rh-justify-content-space-between   { justify-content: space-between; }
.rh-justify-content-space-evenly    { justify-content: space-evenly; }
.rh-justify-content-flex-start      { justify-content: flex-start; }
.rh-justify-content-flex-end        { justify-content: flex-end; }

.rh-flex-wrap           { flex-wrap: wrap; }
.rh-flex-nowrap         { flex-wrap: nowrap; }          /* default */
.rh-flex-wrap-reverse   { flex-wrap: wrap-reverse; }

.rh-flex-grow-0  { flex-grow: 0; }
.rh-flex-grow-1  { flex-grow: 1; }
.rh-flex-shrink-0  { flex-shrink: 0; }
.rh-flex-shrink-1  { flex-shrink: 1; }

.rh-flex-direction-column { flex-direction: column; }
