@use '../definitions/layoutGlobals';

@mixin mobile-padding {
    @media (max-width: map-get(layoutGlobals.$MAP, 'SIDEBAR_SWITCH_WIDTH')) {
        padding-left: map-get(layoutGlobals.$MAP, 'ADAPTIVE_PADDING');
        padding-right: map-get(layoutGlobals.$MAP, 'ADAPTIVE_PADDING');
    }
}

.rh-layout-default {
    grid-column: 3;
    min-width: 0; // Avoids problems with flex items overflowing within the grid

    @include mobile-padding;
}

.rh-layout-full {
    grid-column: 1 / -1;

    @include mobile-padding;
}

.rh-layout-pull-right {
    grid-column: 3 / -1;

    // Ensures margins are flush on the left side
    // Targeting this way because LayoutRows are dynamically added and it's complicated
    // to determine when to conditionally set this value. We may want to look at this in future however.
    > .flex-content {
        margin-left: 0;
    }

    @include mobile-padding;
}

.rh-layout-bg-stretch {
    grid-column: 1 / -1;
    z-index: -1;

    @media (max-width: map-get(layoutGlobals.$MAP, 'SIDEBAR_SWITCH_WIDTH')) {
        display: none;
    }
}
