@use '../definitions/spacing';
@use '../functions/stringReplace' as *;

// Box Sizing
.rh-box-sizing-border-box { box-sizing: border-box; }

// Spacing
.rh-m-auto { margin: auto }
.rh-mt-auto { margin-top: auto }
.rh-mr-auto { margin-right: auto }
.rh-mb-auto { margin-bottom: auto }
.rh-ml-auto { margin-left: auto }
.rh-mx-auto { margin-left: auto; margin-right: auto }
.rh-my-auto { margin-top: auto; margin-bottom: auto }

.rh-m-0 { margin: 0 }
.rh-mt-0 { margin-top: 0 }
.rh-mr-0 { margin-right: 0 }
.rh-mb-0 { margin-bottom: 0 }
.rh-ml-0 { margin-left: 0 }
.rh-mx-0 { margin-left: 0; margin-right: 0 }
.rh-my-0 { margin-top: 0; margin-bottom: 0 }

.rh-p-0 { padding: 0 }
.rh-pt-0 { padding-top: 0 }
.rh-pr-0 { padding-right: 0 }
.rh-pb-0 { padding-bottom: 0 }
.rh-pl-0 { padding-left: 0 }
.rh-px-0 { padding-left: 0; padding-right: 0 }
.rh-py-0 { padding-top: 0; padding-bottom: 0 }

@each $name, $space in spacing.$MAP_AS_NUMBERS {
    .rh-m-#{$name} { margin: $space }
    .rh-p-#{$name} { padding: $space }
    .rh-mt-#{$name} { margin-top: $space }
    .rh-pt-#{$name} { padding-top: $space }
    .rh-mr-#{$name} { margin-right: $space }
    .rh-pr-#{$name} { padding-right: $space }
    .rh-mb-#{$name} { margin-bottom: $space }
    .rh-pb-#{$name} { padding-bottom: $space }
    .rh-ml-#{$name} { margin-left: $space }
    .rh-pl-#{$name} { padding-left: $space }
    .rh-mx-#{$name} { margin-left: $space; margin-right: $space }
    .rh-px-#{$name} { padding-left: $space; padding-right: $space }
    .rh-my-#{$name} { margin-top: $space; margin-bottom: $space }
    .rh-py-#{$name} { padding-top: $space; padding-bottom: $space }
}

// Width/height

// keywords
.rh-width-auto { width: auto }
.rh-width-fit-content { width: fit-content }
.rh-width-min-content { width: min-content }
.rh-width-max-content { width: max-content }

.rh-height-auto { height: auto }
.rh-height-fit-content { height: fit-content }
.rh-height-min-content { height: min-content }
.rh-height-max-content { height: max-content }

.rh-width-0 { width: 0 }
.rh-height-0 { height: 0 }

// as percentages
.rh-width-100p { width: 100% }
.rh-height-100p { height: 100% }

// as pixels
.rh-width-1px { width: 1px }
.rh-width-2px { width: 2px }
.rh-width-3px { width: 3px }

.rh-height-1px { height: 1px }
.rh-height-2px { height: 2px }
.rh-height-3px { height: 3px }

// Min/max-width & min/max-height
.rh-min-width-0 { min-width: 0 }

@each $key, $value in spacing.$MAP_AS_NUMBERS {
    .rh-min-width-#{$key} { min-width: #{$value}; }
    .rh-min-height-#{$key} { min-height: #{$value}; }
    .rh-max-width-#{$key} { max-width: #{$value}; }
    .rh-max-height-#{$key} { max-height: #{$value}; }
}

.rh-max-width-50 { max-width: 50rem; } // Primarily used by calculators
.rh-max-width-75 { max-width: 75rem; } // Primarily used by calculators
.rh-max-width-500px { max-width: 500px; } // Used by ListContainer
.rh-max-width-800px { max-width: 800px; } // Used by a handful of web-components (RichTextBlock)
.rh-max-width-100p { max-width: 100%; }

// Overrides the above classes (applied automatically when siderail is active)
.rh-max-width-override {
    .rh-max-width-50,
    .rh-max-width-75,
    .rh-max-width-500px,
    .rh-max-width-800px {
        max-width: none;
    }
}
