@use '../definitions/colours';
@use '../definitions/font-sizes';
@use '../definitions/spacing';
@use '../definitions/typography';
@use '../mixins/lists';
@use 'sass:map';


// * Note: Prefabs is for a more complex class, where we want to reuse a set of styles.


$STONE: map-get(colours.$MAP, 'STONE');
$STONE_LIGHT: map-get(colours.$MAP, 'STONE_LIGHT');
$STONE_LIGHTEST: map-get(colours.$MAP, 'STONE_LIGHTEST');
$COCONUT: map-get(colours.$MAP, 'COCONUT');


.rh-horizontal-rule {
    border: none;
    border-bottom: 1px solid $STONE;
}

.rh-tag {
    padding: map-get(spacing.$MAP, 'QUARTER') map-get(spacing.$MAP, 'ONE');
    margin: map-get(spacing.$MAP, 'QUARTER');
    font-size: map-get(font-sizes.$MAP, '2XS');
    text-decoration: none;
    text-transform: lowercase;
    outline: none;
    border-radius: 0.333333em;
    background-color: map-get(colours.$MAP, 'STONE_LIGHT');
    color: map-get(colours.$MAP, 'BLACKBERRY');
    transition: background-color 300ms;

    &:link {
        &:hover,
        &:focus { background-color: #c0ced3; }
    }
}

.rh-anchor-with-icon {
    display: inline-flex;
    align-items: center;
    outline: none;
    text-decoration: none;
    transition: color 300ms;
    color: map-get(colours.$MAP, 'BLUEBERRY_DARK');

    > .text-wrapper {
        margin-right: map-get(spacing.$MAP, 'EIGHTH');
        box-shadow: inset 0 -2px transparent;
        transition: box-shadow 300ms;
    }

    > .icon {
        position: relative;
        stroke: map-get(colours.$MAP, 'BLUEBERRY_DARK');
        transition: left 200ms ease-out;
        left: 0;
    }

    &:hover,
    &:focus {
        color: map-get(colours.$MAP, 'BLUEBERRY');
    }

    &:hover > .text-wrapper,
    &:focus > .text-wrapper {
        box-shadow: inset 0 -2px map-get(colours.$MAP, 'BLUEBERRY');
    }

    &:hover > .icon,
    &:focus > .icon {
        stroke: map-get(colours.$MAP, 'BLUEBERRY');
        left: 10px;
    }

    &:active {
        color: map-get(colours.$MAP, 'BLUEBERRY_DARKEST');

        > .text-wrapper {
            box-shadow: inset 0 -2px map-get(colours.$MAP, 'BLUEBERRY_DARKEST');
        }

        > .icon {
            stroke: map-get(colours.$MAP, 'BLUEBERRY_DARKEST');
        }
    }

    &.isDisabled {
        color: map-get(colours.$MAP, 'STONE_DARKEST');
        transition: none;

        > .icon {
            stroke: map-get(colours.$MAP, 'STONE_DARKEST');
        }

        &:hover,
        &:focus {
            > .text-wrapper {
                box-shadow: none;
            }

            > .icon {
                left: 0;
            }
        }
    }

    &.disable-animation {
        > .icon {
            transition: none;
        }

        &:hover,
        &:focus {
            > .icon {
                left: 0;
            }
        }
    }
}

// based on AnchorAnimation
@mixin rh-anchor-animation-base {
    font-weight: 500;
    outline: none;
    text-decoration: none;
    transition: color 300ms, border-bottom-color 300ms;
    border-bottom: 1px solid;

    &:hover,
    &:focus {
        color: map-get(colours.$MAP, 'BLUEBERRY');
        border-bottom-color: transparent;
    }

    &:active {
        color: map-get(colours.$MAP, 'BLUEBERRY_DARKEST');
    }

    &.isDisabled {
        color: map-get(colours.$MAP, 'STONE_DARKEST');
    }
}

.rh-anchor-animation,
.rh-anchor-animation-light {
    @include rh-anchor-animation-base;
    color: map-get(colours.$MAP, 'BLACKBERRY');
    border-bottom-color: map-get(colours.$MAP, 'BLACKBERRY');
}

.rh-anchor-animation-dark {
    @include rh-anchor-animation-base;
    color: $COCONUT;
    border-bottom-color: $COCONUT;
}

.showDot {
    &::after {
        right: 0;
        content: ".";
        font-size: inherit;
        color: map-get(colours.$MAP, 'BLUEBERRY');
    }
}

.rh-ul {
    @include lists.rh-ul;
}

.rh-ol {
    @include lists.rh-ol;
}

.rh-visually-hidden {
    position: absolute;
    left: -9999em;
    width: auto;
    overflow: hidden;
}

/* our most common bordering treatment */
.rh-b-1px-solid-stone  { border:        1px solid $STONE; }
.rh-bt-1px-solid-stone { border-top:    1px solid $STONE; }
.rh-bb-1px-solid-stone { border-bottom: 1px solid $STONE; }

// Accordion set styles
.rh-accordion-blueberry-body {
    box-sizing: border-box;
    margin: 0;

    padding: map-get(spacing.$MAP, 'TWO_AND_A_HALF') map-get(spacing.$MAP, 'ONE_AND_A_HALF') map-get(spacing.$MAP, 'ONE');

    background-color: rgba(map-get(colours.$MAP, 'BLUEBERRY_LIGHTEST'), 0.2);

    border-left: 2px solid map-get(colours.$MAP, 'BLUEBERRY');
}

// common gap sizes -- reminder: short for { row-gap: x; column-gap: x; }
.rh-gap-0_5 { gap: 0.5em; }
.rh-gap-1   { gap: 1em; }
.rh-gap-1_5 { gap: 1.5em; }
.rh-gap-2   { gap: 2em; }

.rh-skip-navigation {
    left: -999em;
    text-decoration: underline;
    padding: 1em;

    &:focus,
    &:hover {
        left: 0;
    }
}

.rh-box-shadow-surround {
    // x-offset, y-offset, blur, spread
    box-shadow: 1px 2px 5px 3px rgba(map-get(colours.$MAP, 'STONE_DARK'), 0.3);
}

.rh-box-shadow-surround-large {
    box-shadow: 1px 3px 10px 4px rgba(map-get(colours.$MAP, 'STONE_DARK'), 0.3);
}


// *** RH Tables - applied to tables produced in the RichTextBlock in the CMS + more ***

// A NOTE ON BACKGROUND COLOURS:
// Body and Footer cells are given background colours in a
// few ways. In total, we have 4 background elements which
// MUST be stacked in this order from lowest to highest:
//
// 1. Plain background
// 2. zebra stripes
// 3. first column highlight cells
// 4. row highlight cells
//
// If they're not layered in that order, the background-colours will
// override one another in weird ways that editors don't expect
// (e.g. zebra stripe overlapping first col highlight).
//
// We also colour #1 & #2 using background-color on ROW elements whereas
// #3 and #4 are coloured CELL elements. This avoids having to do all
// the stacking with specificity alone and breaks it up into two
// groups (rows and cells). Each group then has fewer rules
// competing for top specificity.

$borderRadius: 7px;
$maxRowsCollapsed: 7;


// Note the :nth-child(1n) stuff is because we want row highlights
// to be high specificity and we really need to crank it up to beat
// all the darn zebra styles and their multiple pseudo elements.
@mixin row-highlights {
    &.row-highlight.green {
        td:nth-child(1n),
        th:nth-child(1n) {
            background-color: map-get(colours.$MAP, 'LIME_LIGHTEST');
        }
    }
    &.row-highlight.red {
        td:nth-child(1n),
        th:nth-child(1n) {
            background-color: map-get(colours.$MAP, 'WATERMELON_LIGHTEST');
        }
    }
    &.row-highlight.yellow {
        td:nth-child(1n),
        th:nth-child(1n) {
            background-color: map-get(colours.$MAP, 'YUZU_LIGHTEST');
        }
    }
    &.row-highlight.blue {
        td:nth-child(1n),
        th:nth-child(1n) {
            background-color: map-get(colours.$MAP, 'BLUEBERRY_LIGHTEST');
        }
    }
    &.row-highlight.stone-light {
        td:nth-child(1n),
        th:nth-child(1n) {
            background-color: map-get(colours.$MAP, 'STONE_LIGHT');
        }
    }
}

@mixin tableThemeStyling($tableClassName, $color, $background, $linkColor, $linkHoverColor, $linkActiveColor, $linkVisitedColor) {
    &.#{$tableClassName} {
        thead {
            > tr {
                > th,
                > td {
                    color: map-get(colours.$MAP, $color);
                    background-color: map-get(colours.$MAP, $background);

                    a:link {
                        color: map-get(colours.$MAP, $linkColor);
                        text-decoration: none;
                        border-bottom: 1px solid map-get(colours.$MAP, $linkColor);
                    }
                    a:visited {
                        color: map-get(colours.$MAP, $linkVisitedColor);
                        text-decoration: none;
                        border-bottom: 1px solid map-get(colours.$MAP, $linkVisitedColor);
                    }
                    a:hover {
                        color: map-get(colours.$MAP, $linkHoverColor);
                        text-decoration: none;
                        border-bottom: 1px solid map-get(colours.$MAP, $linkHoverColor);
                    }
                    a:active {
                        color: map-get(colours.$MAP, $linkActiveColor);
                        text-decoration: none;
                        border-bottom: 1px solid map-get(colours.$MAP, $linkActiveColor);
                    }
                }
            }
        }
    }
}

@mixin cellPadding($className, $padding) {
    &.#{$className} {
        th,
        td {
            padding: map-get(spacing.$MAP, $padding);
        }
    }
}

@mixin firstRowCornerRadius($radius) {
    > tr:first-child {
        > td:first-child,
        > th:first-child {
            border-top-left-radius: $radius;
        }
        > td:last-child,
        > th:last-child {
            border-top-right-radius: $radius;
        }
    }
}

@mixin lastRowCornerRadius($radius) {
    > tr:last-child {
        > td:first-child,
        > th:first-child {
            border-bottom-left-radius: $radius;
        }

        > td:last-child,
        > th:last-child {
            border-bottom-right-radius: $radius;
        }
    }
}


.rh-table {
    max-width: 100%;
    font-size: map-get(typography.$MAP, 'TEXT-M');

    &.rh-table-text-s {
        font-size: map-get(typography.$MAP, 'TEXT-S');
    }
    &.rh-table-text-xs {
        font-size: map-get(typography.$MAP, 'TEXT-XS');
    }
    &.rh-table-text-l {
        font-size: map-get(typography.$MAP, 'TEXT-L');
    }

    // assumes we _always_ have a border around the table, typically by borders on the cells, but this approach
    // allows authors to incorrectly have a header row that is not within a thead
    border-top: 1px solid $STONE;

    // ASSUMPTION: We will always use border-collapse: separate; border-spacing: 0;
    // REASON: border-collapse: collapse; makes it impossible to round the corners
    // (only the background-color will be rounded)
    border-collapse: separate; // required if we want rounded corners.
    border-spacing: 0; // required if we want rounded corners.
    border-radius: $borderRadius;

    // *** GENERAL TABLE STYLES ***

    thead,
    tbody,
    tfoot {
        > tr {
            > td,
            > th {
                vertical-align: middle;

                a:link {
                    color: map-get(colours.$MAP, 'BLACKBERRY');
                    text-decoration: none;
                    border-bottom: 1px solid map-get(colours.$MAP, 'BLACKBERRY');
                }
                a:visited {
                    color: map-get(colours.$MAP, 'GRAPE_DARK');
                    text-decoration: none;
                    border-bottom: 1px solid map-get(colours.$MAP, 'GRAPE_DARK');
                }
                a:hover {
                    color: map-get(colours.$MAP, 'BLUEBERRY');
                    text-decoration: none;
                    border-bottom: 1px solid map-get(colours.$MAP, 'BLUEBERRY');
                }
                a:active {
                    color: map-get(colours.$MAP, 'BLUEBERRY_DARK');
                    text-decoration: none;
                    border-bottom: 1px solid map-get(colours.$MAP, 'BLUEBERRY_DARK');
                }
            }
        }
    }

    thead {
        > tr {
            > th,
            > td {
                font-weight: 500;
            }
        }
    }

    thead,
    tbody,
    tfoot {
        > tr {
            background-color: $COCONUT;

            // ASSUMPTION: You would typically only make cells <th>s
            // in the tbody/tfoot if they are in the first column.
            th {
                background-color: map-get(colours.$MAP, 'STONE_LIGHT');
                font-weight: 500;
            }

            td,
            th {
                color: map-get(colours.$MAP, 'BLACKBERRY');

                &.no-wrap {
                    white-space: nowrap;
                }
            }
        }
    }

    /* Captions */
    caption {
        padding: map-get(spacing.$MAP, 'ONE') 0;
        caption-side: bottom;

        /* since we're putting a horizontal scrollbar on the whole
        table and the caption is a part of the table, we want this
        aligned left to give users a better chance of being able to
        see the caption in wide tables. */
        text-align: left;
    }

    /* Sticky first row / first col */

    // A NOTE ON STACKING CONTEXTS:
    // Every cell is in it's own stacking context (formed either by
    // position: relative or position: sticky). The table is basically
    // divided into 4 quadrants with cells in each quadrant placed in
    // one of 4 planes:
    //
    // |------------------|
    // | 4 |      3       |
    // |------------------|
    // |   |              |
    // | 2 |      1       |
    // |   |              |
    // |------------------|
    //
    // This is what enables the correct sticky behaviour and avoids
    // weird overlapping of column shadows.

    // Sticky row
    &.is-sticky-row {
        thead {
            // ASSUMPTION: Only the first row can ever be sticky - if there
            // are more than one row in the thead area, only the first row
            // will stick.
            tr:first-child {
                th,
                td {
                    position: sticky;
                    top: 0;

                    /* If there is vertical overflow, we want header cells
                    to sit on top of all body cells, *including* any sticky
                    cells in the body */
                    z-index: 3;
                }
            }
        }
    }

    // Sticky column
    &.is-sticky-column {
        thead,
        tbody,
        tfoot {
            tr {
                th,
                td {
                    /* Establish a positioning context for the pseudo element */
                    position: relative;

                    &:first-child {
                        position: sticky;
                        left: 0;

                        /* If there is horizontal scrolling, we want cells in the sticky
                        column to sit on top of other cells in the same row. */
                        z-index: 2;

                        &::after {
                            opacity: 0;
                            transition: opacity 300ms ease-out;

                            display: block;
                            content: "";
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            left: 100%;
                            width: 10px;

                            /* Simulate a box shadow on sticky cells. */
                            background-image: linear-gradient(to right, rgba(0,0,0,0.2) 0%, transparent 100%);
                        }
                    }
                }
            }
        }

        // Annoyingly, we are forced to set plain cells and zebra stripe
        // colours on cells directly when a column is sticky, because
        // sticky cells need to overlap and occlude other cells.
        &.zebra {
            tbody {
                tr:nth-child(even) {
                    td:first-child {
                        background-color: $STONE_LIGHTEST;
                    }
                }
            }
        }
        tbody,
        tfoot {
            tr {
                td:first-child {
                    background-color: $COCONUT;
                }

                @include row-highlights;
            }
        }

        // Show/hide the shadow when scrolled.
        &.is-scrolled {
            thead,
            tbody,
            tfoot {
                tr {
                    th,
                    td {
                        &:first-child {
                            &::after {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }

    // Upper-left-most cell stacks on top of all.
    thead {
        tr:first-child {
            th:first-child,
            td:first-child {
                z-index: 4;
            }
        }
    }

    // *** PADDING ***

    // All cells regardless of what section they're nested in (thead/tbody/tfoot)
    th,
    td {
        padding: map-get(spacing.$MAP, 'SEVEN_EIGHTHS'); // this is the default and is same as "padding-medium"
    }
    @include cellPadding('padding-small', 'HALF');
    @include cellPadding('padding-medium', 'SEVEN_EIGHTS');
    @include cellPadding('padding-large', 'ONE');


    // *** TABLE THEME ***

    @include tableThemeStyling(
        $tableClassName: 'table-theme-stone',
        $color: 'BLACKBERRY',
        $background: 'STONE',
        $linkColor: 'BLACKBERRY',
        $linkHoverColor: 'BLUEBERRY',
        $linkActiveColor: 'BLUEBERRY_DARK',
        $linkVisitedColor: 'GRAPE_DARK',
    );
    @include tableThemeStyling(
        $tableClassName: 'table-theme-blackberry',
        $color: 'COCONUT',
        $background: 'BLACKBERRY',
        $linkColor: 'COCONUT',
        $linkHoverColor: 'BLUEBERRY',
        $linkActiveColor: 'BLUEBERRY_LIGHT',
        $linkVisitedColor: 'GRAPE',
    );
    @include tableThemeStyling(
        $tableClassName: 'table-theme-blueberry-darkest',
        $color: 'COCONUT',
        $background: 'BLUEBERRY_DARKEST',
        $linkColor: 'COCONUT',
        $linkHoverColor: 'BLUEBERRY_LIGHT',
        $linkActiveColor: 'BLUEBERRY',
        $linkVisitedColor: 'GRAPE',
    );


    // *** ROUNDED CORNERS ***

    // Add border-radius to corner cells
    /* WARNING: first-child/last-child doesn't work
    so hot if those cells are merged! */

    // Default FIRST rows in both <thead> & <tbody> to have round corners...
    thead,
    tbody {
        @include firstRowCornerRadius($borderRadius);
    }

    // ...BUT remove rounded corners from <tbody> if there's a <thead>.
    thead + tbody {
        @include firstRowCornerRadius(unset);
    }

    // Default LAST rows in both <tbody> & <tfoot> to have round corners...
    tbody,
    tfoot {
        @include lastRowCornerRadius($borderRadius);
    }

    // ...BUT remove rounded corners from last <tbody> row if there's a <tfoot>.

    // NOTE: TinyMCE positions the <tfoot> ABOVE both the <thead> and the <tbody> in DOM 🤯!
    // This sounds nuts but it's totally allowed in HTML. This selects any
    // <tbody> that *generally* follows a <tfoot> (can have other elements between
    // the two - such as <thead>). Both of these selectors target the tbody.
    tbody:has(+ tfoot),     // - typical: tbody followed by tfoot in the DOM
    tfoot ~ tbody {         // - TinyMCE: tfoot precedes tbody in the DOM
        @include lastRowCornerRadius(unset);
    }


    // *** BORDERS ***

    thead,
    tbody,
    tfoot {
        td,
        th {
            // no border-top here intentionally
            border-right: 1px solid $STONE;
            border-bottom: 1px solid $STONE;

            &:first-child {
                border-left: 1px solid $STONE;
            }
        }
    }

    // when theme is Stone, we can’t use it to separate cells horizontally
    &.table-theme-stone:not(.no-borders) {
        thead,
        tfoot {
            tr:first-child {
                td,
                th {
                    // no border-top here intentionally
                    border-right: 1px solid $COCONUT;
                    border-bottom: 1px solid $COCONUT;

                    &:last-child {
                        border-right: 1px solid $STONE;
                    }
                }
            }
        }
    }

    &.no-borders {
        thead,  // need it, else thead cells have border-bottom
        tbody,
        tfoot {
            tr {
                td,
                th {
                    border-top: none;
                    border-right: none;
                    border-bottom: none;
                }

                td:last-child,
                th:last-child {
                    border-right: 1px solid $STONE;
                }
            }
        }

        // Apply border to BOTH tbody & tfoot
        tbody,
        tfoot {
            tr:last-child {
                td,
                th {
                    border-bottom: 1px solid $STONE;
                }
            }
        }
    }


    // *** ZEBRA STRIPING ***

    &.zebra {
        > tbody {
            > tr:nth-child(even) {
                background-color: $STONE_LIGHTEST;
            }
        }
    }

    // *** ROW HIGHLIGHTING ***

    > tbody,
    > tfoot {
        > tr {
            @include row-highlights;
        }
    }

    // *** EXPAND/COLLAPSE ***/

    &.is-expandable {
        &:not(.is-expanded) {
            tbody {
                tr:nth-child(1n + #{$maxRowsCollapsed}) {
                    display: none;
                }
            }
        }
    }
}
