@use '../definitions/colours';
@use '../functions/stringReplace' as *;


$RH_PREFIX: 'rh-';
$FOREGROUND_PREFIX: $RH_PREFIX + 'fg-';
$BACKGROUND_PREFIX: $RH_PREFIX + 'bg-';

// Default states
@each $name, $colour in colours.$MAP {
    // Foreground color
    .#{$FOREGROUND_PREFIX}#{to-lower-case(string-replace($name, '_', '-'))} {
        color: #{$colour};
    }

    // Background color
    .#{$BACKGROUND_PREFIX}#{to-lower-case(string-replace($name, '_', '-'))} {
        background-color: #{$colour};
    }

    // Fill
    .#{$RH_PREFIX}fill-#{to-lower-case(string-replace($name, '_', '-'))} {
        fill: #{$colour};
    }

    // Stroke
    .#{$RH_PREFIX}stroke-#{to-lower-case(string-replace($name, '_', '-'))} {
        stroke: #{$colour};
    }
}

// Hover states
@each $name, $colour in colours.$MAP {
    .hover--#{$FOREGROUND_PREFIX}#{to-lower-case(string-replace($name, '_', '-'))}:hover {
        color: #{$colour};
    }

    .hover--#{$BACKGROUND_PREFIX}#{to-lower-case(string-replace($name, '_', '-'))}:hover {
        background-color: #{$colour};
    }

    .hover--#{$RH_PREFIX}fill-#{to-lower-case(string-replace($name, '_', '-'))}:hover {
        fill: #{$colour};
    }

    .hover--#{$RH_PREFIX}stroke-#{to-lower-case(string-replace($name, '_', '-'))}:hover {
        stroke: #{$colour};
    }
}

// Active states
@each $name, $colour in colours.$MAP {
    .active--#{$FOREGROUND_PREFIX}#{to-lower-case(string-replace($name, '_', '-'))}:active {
        color: #{$colour};
    }

    .active--#{$BACKGROUND_PREFIX}#{to-lower-case(string-replace($name, '_', '-'))}:active {
        background-color: #{$colour};
    }

    .active--#{$RH_PREFIX}fill-#{to-lower-case(string-replace($name, '_', '-'))}:active {
        fill: #{$colour};
    }

    .active--#{$RH_PREFIX}stroke-#{to-lower-case(string-replace($name, '_', '-'))}:active {
        stroke: #{$colour};
    }
}

// Focus states
@each $name, $colour in colours.$MAP {
    .focus--#{$FOREGROUND_PREFIX}#{to-lower-case(string-replace($name, '_', '-'))}:focus {
        color: #{$colour};
    }

    .focus--#{$BACKGROUND_PREFIX}#{to-lower-case(string-replace($name, '_', '-'))}:focus {
        background-color: #{$colour};
    }

    .focus--#{$RH_PREFIX}fill-#{to-lower-case(string-replace($name, '_', '-'))}:focus {
        fill: #{$colour};
    }

    .focus--#{$RH_PREFIX}stroke-#{to-lower-case(string-replace($name, '_', '-'))}:focus {
        stroke: #{$colour};
    }
}