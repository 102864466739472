@use '../definitions/colours';
@use '../definitions/font-sizes';
@use '../definitions/spacing';
@use '../definitions/typography';
@use '../mixins/lists';

$MARGIN_BLOCK_SMALL: 0.83em;
$MARGIN_BLOCK_MEDIUM: 1em;
$MARGIN_BLOCK_LARGE: 1.25em;

.rich-text {
    h1 {
        font-family: inherit;
        font-size: map-get(typography.$MAP, 'TITLE-2XL');
        font-weight: 500;
        line-height: 1.25;

        margin-block-start: #{$MARGIN_BLOCK_MEDIUM};
        margin-block-end: #{$MARGIN_BLOCK_MEDIUM};
    }

    h2 {
        font-family: inherit;
        font-size: map-get(typography.$MAP, 'TITLE-L');
        font-weight: 500;
        line-height: 1.307692308;

        margin-block-start: #{$MARGIN_BLOCK_SMALL};
        margin-block-end: #{$MARGIN_BLOCK_SMALL};
    }

    h3 {
        font-family: inherit;
        font-size: map-get(typography.$MAP, 'TITLE-S');
        font-weight: 500;
        line-height: 1.4;

        margin-block-start: #{$MARGIN_BLOCK_SMALL};
        margin-block-end: #{$MARGIN_BLOCK_SMALL};
    }

    h4 {
        font-family: inherit;
        font-size: map-get(typography.$MAP, 'TITLE-XS');
        font-weight: 500;
        line-height: 1.555555556;

        margin-block-start: #{$MARGIN_BLOCK_SMALL};
        margin-block-end: #{$MARGIN_BLOCK_SMALL};
    }

    h5 {
        font-family: inherit;
        font-size: map-get(typography.$MAP, 'TITLE-2XS');
        font-weight: 500;
        line-height: 1.5;

        margin-block-start: #{$MARGIN_BLOCK_SMALL};
        margin-block-end: #{$MARGIN_BLOCK_SMALL};
    }

    p {
        font-family: inherit;
        font-size: map-get(typography.$MAP, 'TEXT-L');
        line-height: 2;

        margin-block-start: #{$MARGIN_BLOCK_LARGE};
        margin-block-end: #{$MARGIN_BLOCK_LARGE};
    }

    img {
        max-width: 100%;
        height: auto;

        &.alignleft {
            float: left;
            margin: 0.5em 1em 0.5em 0;
        }

        &.aligncenter {
            display: block;
            margin-left: auto;
            margin-right: auto;
        }

        &.alignright {
            float: right;
            margin: 0.5em 0 0.5em 1em;
        }
    }

    a {
        line-height: inherit;

        /* All standard anchors should include this animation mixin, non-standard should not */
        &:not(.has-image, .rh-table * a) {
            padding: 0 1px;

            font-weight: 500;
            text-decoration: none;
            color: map-get(colours.$MAP, 'BLACKBERRY');

            border-bottom: 1px solid map-get(colours.$MAP, 'BLACKBERRY');
            outline: none;

            transition: color 300ms,
                        border-bottom-color 300ms;

            &:hover,
            &:focus {
                color: map-get(colours.$MAP, 'BLUEBERRY');
                border-bottom: 1px solid map-get(colours.$MAP, 'BLUEBERRY');
            }

            &:active {
                color: map-get(colours.$MAP, 'BLUEBERRY_DARK');
                border-bottom: 1px solid map-get(colours.$MAP, 'BLUEBERRY_DARK');
                animation: none;
            }
        }
    }

    ul {
        @include lists.rh-ul;
    }

    ol {
        @include lists.rh-ol;
    }

    table:not(.rh-table) {
        border-collapse: collapse;
        margin-left: auto;
        margin-right: auto;

        a {
            text-decoration: none;
            outline: none;
            box-shadow: inset 0 -2px map-get(colours.$MAP, 'BLACKBERRY');
            transition:
                color 0.3s,
                box-shadow 0.3s;

            color: map-get(colours.$MAP, 'BLACKBERRY');

            &:hover,
            &:focus {
                color: map-get(colours.$MAP, 'BLUEBERRY');
                box-shadow: inset 0 -2px map-get(colours.$MAP, 'BLUEBERRY');
            }

            &:active {
                color: map-get(colours.$MAP, 'BLUEBERRY_DARK');
            }
        }

        tr {
            &:first-child {
                th,
                td {
                    vertical-align: top;
                }
            }
        }

        th,
        td  {
            line-height: 20px; /* I'd like to put this elsewhere, but we don't have as standard right now */
            vertical-align: middle;

            padding-left: map-get(spacing.$MAP, 'TWO');
            padding-right: map-get(spacing.$MAP, 'TWO');

            &:first-child {
                padding-left: map-get(spacing.$MAP, 'ONE_AND_A_HALF');
            }

            &:last-child {
                padding-right: map-get(spacing.$MAP, 'ONE_AND_A_HALF');
            }
        }

        th {
            font-size: map-get(font-sizes.$MAP, 'XS');
            font-weight: 500;
            text-align: left;

            padding-top: map-get(spacing.$MAP, 'ONE');
            padding-bottom: map-get(spacing.$MAP, 'ONE');

            border-bottom: 1px solid map-get(colours.$MAP, 'STONE');
        }

        td {
            padding-top: map-get(spacing.$MAP, 'ONE_AND_A_HALF');
            padding-bottom: map-get(spacing.$MAP, 'ONE_AND_A_HALF');

            border-bottom: 1px solid map-get(colours.$MAP, 'STONE');
        }

        tbody th {
            padding-top: map-get(spacing.$MAP, 'ONE_AND_A_HALF');
        }
    }

    // *** RH-Table-related ***
    // Allows scrollbar on tables + provides context
    // for sticky rows/columns.
    .overflow {
        overflow-x: auto;
        overflow-y: auto;

        // Apply max-height to overflow container conditionally.
        &:has(table.is-sticky-row:not(.is-expandable)),
        &:has(table.is-sticky-row.is-expandable.is-expanded) {
            max-height: 500px;
        }

        // Many of Ratehub.ca's users are stuck on Chrome 102
        // for inexplicable reasons. That doesn't support :has()
        // but pretty much everything else does. This just
        // applies max-height all the time for that browser
        // (should look and work the same as supporting browsers
        // in most scenarios).
        @supports not selector(:has(a)) {
            max-height: 500px;
        }

        // Some browsers like to chop off the
        // bottom 1px table border for no good reason.
        padding-bottom: 1px;

        // Mask the top left corner of tables.
        //
        // Otherwise, non-sticky header cells can poke out from underneath the
        // round corners when scrolled. Top-left is the only corner where this
        // problem is super noticable and the other corners can't ha ve this
        // added all the time without introducing *other* problems.
        border-top-left-radius: 7px;
    }
    .button-view-more {
        transition: color 300ms ease, stroke 300ms ease, fill 300ms ease;
    }

    &.no-vertical-margins {
        > *:first-child {
            margin-top: 0;
        }
        > *:last-child {
            margin-bottom: 0;
        }
    }

    &.light-variant {
        h1,
        h2,
        h3,
        h4,
        h5,
        p,
        p {
            color: map-get(colours.$MAP, 'COCONUT');
        }

        ul,
        ol {
            li {
                color: map-get(colours.$MAP, 'COCONUT');
            }
        }

        ul {
            li {
                &::before {
                    background-color: map-get(colours.$MAP, 'BLUEBERRY');
                }
            }
        }

        a {
            &:not(.has-image) {
                color: map-get(colours.$MAP, 'COCONUT');
                border-bottom-color: map-get(colours.$MAP, 'COCONUT');
            }
        }
    }
}
