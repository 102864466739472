@use '../definitions/colours';
@use '../definitions/fonts';
@use 'sass:map';

*::selection {
    /* The selection ignores opacity 1 so we have to set it to 0.99
        to get the look we want */
    background-color: rgba(map-get(colours.$MAP, 'BLUEBERRY_LIGHT'), 0.99);
    color: map-get(colours.$MAP, 'BLACKBERRY');
}

/* Image selection defined separately because an opacity of 0.99 makes the image invisible when highlighted */
img::selection {
    background-color: rgba(map-get(colours.$MAP, 'BLUEBERRY_LIGHT'), 0.70)
}

html {
    font:
        400
        16px/1.5
        fonts.$FONT_FAMILY_STACK;
    color: map-get(colours.$MAP, 'BLACKBERRY');
}

@media(max-width: 1366px) {
    html {
        font-size: 14px;
    }
}

button {
    color: #{map.get(colours.$MAP, 'BLACKBERRY')};
    background-color: #{map.get(colours.$MAP, 'TRANSPARENT')};
    border: 1px solid #{map.get(colours.$MAP, 'STONE_DARK')};
    cursor: pointer;
    text-align: center;
    font-family: inherit;

    /* Old Firefox (like < v90) added an outline on the inside of buttons and
    this removes it. Can probably delete at some point but sadly, it seems like
    we still have significant traffic on old versions. */
    &::-moz-focus-inner {
        border-width: 0;
    }

    &:disabled {
        cursor: default;
    }
}

strong,
b {
    font-weight: 500;
}
