$BASE_UNIT: 'rem';
$BASE_VALUE: 1;
// Please do not use any of these values directly on SVG tags as they use rem units.
$MAP: (
    '3XS': #{$BASE_VALUE * 0.75}#{$BASE_UNIT},
    '2XS': #{$BASE_VALUE}#{$BASE_UNIT},
    'XS': #{$BASE_VALUE * 1.5}#{$BASE_UNIT},
    'S': #{$BASE_VALUE * 2}#{$BASE_UNIT},
    'M': #{$BASE_VALUE * 2.5}#{$BASE_UNIT},
    'L': #{$BASE_VALUE * 3.0}#{$BASE_UNIT},
    'XL': #{$BASE_VALUE * 4.0}#{$BASE_UNIT},
    '2XL': #{$BASE_VALUE * 5.0}#{$BASE_UNIT},
    '3XL': #{$BASE_VALUE * 8.0}#{$BASE_UNIT},
    '4XL': #{$BASE_VALUE * 12}#{$BASE_UNIT},
    '5XL': #{$BASE_VALUE * 16}#{$BASE_UNIT},
    '6XL': #{$BASE_VALUE * 22}#{$BASE_UNIT},
);
